import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const PodStatement = Loadable(lazy(() => import('./PodStatement')));
const PodStReport = Loadable(lazy(() => import('./PodStReport')));
const DemoScan = Loadable(lazy(() => import('./DemoScan')));

const PodRoutes = [
  { path: '/podstatement', element: <PodStatement /> },
  { path: '/podstatementreport', element: <PodStReport /> },
  { path: '/demoscan', element: <DemoScan /> },
];

export default PodRoutes;
